<template>
  <v-dialog v-model="isActive" persistent :light="true" max-width="420px">
    <v-card>
      <v-toolbar flat color="black" :dark="true">
        <v-toolbar-title>{{ titles[step - 1] }}</v-toolbar-title>
        <v-spacer />
        <v-btn v-show="step > 1" text @click="step -= 1">
          <v-icon left>mdi-arrow-left</v-icon>
          <slot name="back"> Cofnij </slot>
        </v-btn>
      </v-toolbar>

      <!-- change condition -->
      <v-card-text class="pa-0" v-if="isActive">
        <v-row v-if="message" align="center" justify="center" class="ma-0">
          <v-col lg="12" md="12" sm="12" cols="12">
            <v-alert type="error" class="ma-0">
              {{ message }}
            </v-alert>
          </v-col>
        </v-row>

        <v-date-picker
          v-show="step === 1"
          v-model="selectedDate"
          year-icon="mdi-calendar-blank"
          color="amber darken-1"
          :first-day-of-week="1"
          locale="pl-PL"
          full-width
        ></v-date-picker>

        <v-time-picker
          v-show="!dateOnly && step === 2"
          v-model="selectedTimeStart"
          format="24hr"
          color="amber darken-1"
          locale="pl-PL"
          full-width
        ></v-time-picker>

        <v-time-picker
          v-show="!dateOnly && step === 3"
          v-model="selectedTimeEnd"
          format="24hr"
          color="amber darken-1"
          locale="pl-PL"
          full-width
        ></v-time-picker>

        <slot name="inner">
          <v-row class="mx-4 pa-auto" v-show="step === 1">
            <v-checkbox
              v-model="checkboxes.confirmDate"
              label="Data ustalona z klientem"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-row>

          <v-row class="mx-4 pa-auto" v-show="step === 1">
            <v-checkbox
              v-model="checkboxes.sendNotification"
              label="Wyślij powiadomienie w aplikacji (dzwonek)"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-row>

          <v-row class="mx-4 pa-auto" v-show="step === 1">
            <v-checkbox
              v-model="checkboxes.sendMail"
              label="Wyślij maila z powiadomieniem do klienta"
              color="primary"
              hide-details
            ></v-checkbox>
          </v-row>
        </slot>
      </v-card-text>

      <v-card-actions class="mt-4 pa-2">
        <v-btn
          v-show="step === 1 || step === 3"
          color="amber accent-3"
          class="grey--text text--darken-4"
          :x-large="true"
          v-tilt
          outlined
          @click="close()"
        >
          <slot name="cancel"> Anuluj </slot>
        </v-btn>

        <v-btn
          v-show="step === 2"
          color="amber accent-3"
          class="grey--text text--darken-4"
          :x-large="true"
          v-tilt
          outlined
          @click="skip()"
        >
          <slot name="skip"> Pomiń </slot>
        </v-btn>

        <v-spacer />

        <v-btn
          color="amber accent-3"
          class="grey--text text--darken-4"
          :x-large="true"
          v-tilt
          @click="save()"
          :disabled="validator"
        >
          <div v-if="step === 3 || (step === 1 && dateOnly)">
            <slot name="save"> Zapisz </slot>
          </div>

          <div v-else>
            <slot name="next"> Dalej </slot>
          </div>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  export default {
    name: 'DatePicker',
    props: {
      value: {
        type: Boolean,
        required: true,
      },
      dateOnly: {
        type: Boolean,
        default: true,
      },
      title: {
        type: [String, Array],
        default: () => [],
      },
      selected: {
        type: [String, Date],
        default: () => new Date(),
      },
    },
    data: () => ({
      step: 1,
      titles: ['Edycja daty', 'Czas od', 'Czas do'],
      selectedDate: new Date().toISOString().substr(0, 10),
      selectedTimeStart: '',
      selectedTimeEnd: '',
      checkboxes: {
        confirmDate: false,
        sendNotification: false,
        sendMail: false,
      },
      message: '',
    }),
    watch: {
      isActive() {
        this.reset();
      },
    },
    computed: {
      isActive: {
        get() {
          return this.value;
        },
        set(val) {
          this.$emit('input', val);
        },
      },
      validator: function () {
        if (this.step === 1) return !this.selectedDate;
        else if (this.step === 2) {
          return !this.selectedTimeStart;
        } else {
          return !this.selectedTimeEnd;
        }
      },
    },
    methods: {
      reset() {
        this.step = 1;

        if (!this.title || (typeof this.title !== 'string' && !(this.selected instanceof Array))) {
          this.titles = ['Edycja daty', 'Czas od', 'Czas do'];
        } else {
          if (typeof this.title === 'string') {
            this.titles[0] = this.title;
          } else {
            this.titles = this.title;
          }
        }

        this.selectedDate =
          typeof this.selected === 'string'
            ? this.selected
            : this.selected instanceof Date
            ? this.selected.toISOString().substr(0, 10)
            : new Date().toISOString().substr(0, 10);
        this.selectedTimeStart = '';
        this.selectedTimeEnd = '';
        this.checkboxes = {
          confirmDate: false,
          sendMail: false,
          sendNotification: false,
        };
      },
      close() {
        this.$emit('input', false);
      },
      skip() {
        this.selectedTimeStart = '';
        this.selectedTimeEnd = '';
        this.step = 3;
        this.save();
      },
      save() {
        if (!this.dateOnly) {
          if (this.step === 1) return (this.step = 2);
          else if (this.step === 2) return (this.step = 3);
        }

        this.$emit('save', {
          date: this.selectedDate,
          timeStart: this.selectedTimeStart,
          timeEnd: this.selectedTimeEnd,
          checkboxes: this.checkboxes,
        });
        this.$emit('input', false);
      },
    },
  };
</script>

<style scoped>
  .v-btn--outlined {
    border: thin solid #ffc400 !important;
  }
</style>
